import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import BlogPostPreview from "./blogPostPreview";
import styles from "./blog-post-preview-list.module.css";
import ReactPaginate from 'react-paginate';

export default function BlogPostPreviewList(props) {

  // Pagination states
  const [currentNodes, setCurrentNodes] = useState(null); 
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);

  // Blogs per page - could maybe have this as a Sanity setting?
  const perPage = 5;

  // what blogs to show
  useEffect(() => {
    const endOffset = offset + perPage; 
    setCurrentNodes(props.nodes.slice(offset, endOffset));
    setPageCount(Math.ceil(props.nodes.length / perPage));
  }, [offset, perPage]);

  // Request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * perPage) % props.nodes.length;
    setOffset(newOffset);
    window.scrollTo(0, 360);
  };

  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {currentNodes &&
          currentNodes.map((node) => (
            <li key={node.id}>
              <BlogPostPreview {...node} clientConfig={props.clientConfig} isInList />
            </li>
          ))}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
      {pageCount > 1 && <ReactPaginate
          containerClassName="page-pagination flex justify-center space-x-1 py-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item px-4 py-2 text-gray-700 bg-gray-200"
          pageLinkClassName="page-link px-2 py-2"
          previousClassName="page-item px-4 py-2 text-gray-700 bg-gray-200"
          previousLinkClassName="page-link px-2 py-2"
          nextClassName="page-item px-4 py-2 text-gray-700 bg-gray-200"
          nextLinkClassName="page-link px-2 py-2"
          breakLabel="..."
          breakClassName="page-item px-4 py-2 text-gray-700 bg-gray-200"
          breakLinkClassName="page-link px-4 py-2"
          activeClassName="active bg-gray-400"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
        />}
    </div>
  );
}

BlogPostPreviewList.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};