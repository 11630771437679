import React from "react";
import { format } from "date-fns";
import { Link } from "gatsby";
import { buildImageObj, cn, getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

import styles from "./blog-post-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";

export default function BlogPostPreview(props) {
  return (
    <div
      className={
        props.isInList ? `border-b-2 pb-8 ${styles.inList}` : `border-b-2 pb-8 ${styles.inGrid}`
      }
    >
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage), props.clientConfig)
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto("format")
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      <div className={styles.text}>
        <h3 className={cn(responsiveTitle3, styles.title)}>
          <Link to={getBlogUrl(props.slug.current)}>{props.title}</Link>
        </h3>
        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            <PortableText blocks={props._rawExcerpt} />
          </div>
        )}
        <div className="flex flex-row justify-between items-center w-full">
          <div className={styles.date}>{format(props.publishedAt, "MMMM Do, YYYY")}</div>
          <Link to={getBlogUrl(props.slug.current)}>Read More</Link>
        </div>
      </div>
    </div>
  );
}
